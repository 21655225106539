/** @format */

import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./pages/common/animate.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./app.scss";
import { Helmet } from "react-helmet";

const Home = lazy(() => import("./pages/Home"));
const LifeInsurance = lazy(() => import("./pages/LifeInsurance"));
const LifeInsuranceQuote = lazy(() => import("./pages/LifeInsuranceQuote"));
const LifeInsuranceLB = lazy(() => import("./pages/LifeInsuranceLB"));
const HomeLoan = lazy(() => import("./pages/HomeLoan"));
const HomeLoanFB = lazy(() => import("./pages/HomeLoanFB"));
const HomeLoanFB2 = lazy(() => import("./pages/HomeLoanFB2"));
// const Refinance = lazy(() => import("./pages/Refinance"));
// const RefinanceFB = lazy(() => import("./pages/RefinanceFB"));
const Solar = lazy(() => import("./pages/Solar"));
const SolarFB = lazy(() => import("./pages/SolarFB"));
const Hearing = lazy(() => import("./pages/Hearing"));
const Debt = lazy(() => import("./pages/Debt"));
const VirginHomeLoan = lazy(() => import("./pages/VirginHomeLoan"));
const DebtFB = lazy(() => import("./pages/DebtFB"));
const LifeDemo = lazy(() => import("./pages/LifeDemo"));
const HealthInsurance = lazy(() => import("./pages/HealthInsurance"));
const SolarFB2 = lazy(() => import("./pages/SolaFB2"));
const TeleHomeLoans = lazy(() => import("./pages/TeleHomeLoans"));

export default function App() {
  return (
    <Router>
      <Helmet>
        <title>Makescents</title>
      </Helmet>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route path="/home-loan">
            <HomeLoan />
          </Route>
          <Route path="/home-loan-fb">
            <HomeLoanFB />
          </Route>
          <Route path="/home-loan-fb-2">
            <HomeLoanFB2 />
          </Route>
          {/* <Route path="/refinance">
            <Refinance />
          </Route> */}
          {/* <Route path="/refinance-fb">
            <RefinanceFB />
          </Route> */}
          <Route path="/life-insurance">
            <LifeInsurance />
          </Route>
          <Route path="/life-insurance-quote">
            <LifeInsuranceQuote />
          </Route>
          <Route path="/life-insurance-lb">
            <LifeInsuranceLB />
          </Route>
          <Route path="/solar">
            <Solar />
          </Route>
          <Route path="/solar-fb">
            <SolarFB />
          </Route>
          <Route path="/solar-fb-2">
            <SolarFB2 />
          </Route>
          <Route path="/hearing">
            <Hearing />
          </Route>
          <Route path="/debt">
            <Debt />
          </Route>
          <Route path="/debt-fb">
            <DebtFB />
          </Route>
          <Route path="/virgin-home-loan">
            <VirginHomeLoan />
          </Route>
          <Route path="/health-insurance">
            <HealthInsurance />
          </Route>
          <Route path="/life-demo">
            <LifeDemo />
          </Route>

          <Route path="/tele-home-loans">
            <TeleHomeLoans />
          </Route>

          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
}
